export {};

function encodeUtf8(this: string): string {
    return unescape(encodeURIComponent(this));
}

function decodeUtf8(this: string): string {
    return decodeURIComponent(escape(this));
}

String.prototype.encodeUtf8 = encodeUtf8;
String.prototype.decodeUtf8 = decodeUtf8;
