import {NgModule} from '@angular/core';
import {MatBadgeModule} from '@angular/material/badge';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {TicketV2Module} from '@app/tickets/ticket-v2.module';
import {FuseSearchBarModule, FuseShortcutsModule} from '@fuse/components';
import {FuseSharedModule} from '@fuse/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {CurrentTicketModule} from '@ticket/current-ticket-switcher/current-ticket.module';
import {AvatarModule} from '@libs/ui/src';
import {ToolbarComponent} from '@core/layouts/components/toolbar/toolbar.component';
import {CoreModule} from '@core/core.module';
import {FavoritePanelComponent} from '@core/components/favorites/favorite-panel.component';
import {LanguageSelectionMenuComponent} from '@core/components/language-selection-menu/language-selection-menu.component';
import {VersionChangeLogDialogComponent} from '@core/components/version-change-log/version-change-log-dialog.component';

@NgModule({
    declarations: [ToolbarComponent],
    imports: [
        RouterModule,
        CommonModule,
        FuseSharedModule,
        FuseSearchBarModule,
        FuseShortcutsModule,
        BrowserAnimationsModule,

        // Material
        MatBadgeModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        MatTooltipModule,
        TicketV2Module,
        TranslateModule,
        CurrentTicketModule,
        AvatarModule,
        CoreModule,
        FavoritePanelComponent,
        LanguageSelectionMenuComponent,
        VersionChangeLogDialogComponent
    ],
    exports: [ToolbarComponent]
})
export class ToolbarModule {
}
