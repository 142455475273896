export class ApiResponseModel {
    httpStatusCode: number;

    lastModified: Date;

    deletedSinceLastModified: number[] = [];

    message: string;

    redux: boolean;

    result: any[];

    constructor(data: any = null) {
        if (data) {
            Object.assign(this, data);
        }

    }

}
