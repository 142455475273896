import {HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable, StaticClassProvider} from '@angular/core';
import {Observable} from 'rxjs';
import {JwtService} from '@core/services/jwt.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    /**
     * Empty constructor
     */
    constructor(private readonly jwtService: JwtService) {
    }

    /**
     *
     *
     *
     *
     * @param request
     * @param next
     * @returns Observable<HttpEvent<any>>
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let language = localStorage.getItem('language');
        if (!language) {
            language = 'de';
        }

        // add authorization header with jwt token if available
        const token = this.jwtService.token;

        if (!request.headers.has('skip-content-type')) {
            request = request.clone({
                setHeaders: {
                    'Content-Type': 'application/json'
                }
            });
        }

        if (token) {
            request = request.clone({
                setHeaders: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'Accept-Language': `${language}`
                }
            });
        } else {
            request = request.clone({
                setHeaders: {
                    'skip-content-type': 'true'
                }
            });
        }
        return next.handle(request);
    }
}

export const JwtInterceptorProvider: StaticClassProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: JwtInterceptor,
    multi: true,
    deps: [JwtService]
};
