import {HTTP_INTERCEPTORS, HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable, StaticClassProvider} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ApiResponseModel} from '../models/api/api-response.model';
import {AuthenticationService} from '@core/services/global/authentication.service';
import {SnackbarService} from '@core/services/snackbar.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly snackbarService: SnackbarService,
        private readonly translate: TranslateService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return next.handle(request).pipe(
            map((response) => {
                if (response instanceof HttpResponse && response.body && response.body.httpStatusCode) {
                    const apiReponse: ApiResponseModel = new ApiResponseModel(response.body);
                    if (
                        apiReponse.httpStatusCode === 200 &&
                        apiReponse.result != null &&
                        apiReponse.message !== 'Ok' &&
                        (request.method === 'POST' || request.method === 'PUT' || request.method === 'DELETE')
                    ) {
                        // Success Notification
                        this.snackbarService.open(apiReponse.message);
                    }

                    if (apiReponse.redux) {
                        return response.clone({body: apiReponse});
                    }


                    response = response.clone({body: apiReponse.result});
                }

                return response;
            }),
            catchError((error) => {
                if (error instanceof HttpErrorResponse) {
                    switch (error.status) {
                        case 400: {
                            const errMsg = error.error != null && Array.isArray(error.error.errors) ? error.error.errors[0].message : error.error;
                            this.snackbarService.open(errMsg);
                            break;
                        }
                        case 401: {
                            // auto logout if 401 response returned from api
                            this.authenticationService.logout();
                            break;
                        }
                        case 403: {
                            this.snackbarService.open(this.translate.instant('GLOBAL.HTTP_ERROR_UNAUTHORIZED'));
                            break;
                        }
                        case 404: {
                            this.snackbarService.open(this.translate.instant('GLOBAL.HTTP_ERROR_NOTFOUND'));
                            break;
                        }
                        default:
                            this.snackbarService.open(error.error ?? this.translate.instant('GLOBAL.HTTP_ERROR_DEFAULT'));
                            return throwError(error);
                    }
                }

                return throwError(error);
            })
        );
    }
}

export const ErrorInterceptorProvider: StaticClassProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true,
    deps: [AuthenticationService, SnackbarService, TranslateService]
};
