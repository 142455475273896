import {Component} from '@angular/core';
import {DialogHostModule} from '@libs/ui/src';
import {CHANGE_LOG} from '@core/components/version-change-log/version-change-log';
import {MatDialog} from '@angular/material/dialog';
import {MatLegacyDialogRef} from '@angular/material/legacy-dialog';
import {VersionChangeLogComponent} from '@core/components/version-change-log/version-change-log.component';
import {TranslateModule} from '@ngx-translate/core';

@Component({
    selector: 'omt-version-change-log-dialog',
    standalone: true,
    imports: [DialogHostModule, VersionChangeLogComponent, TranslateModule],
    templateUrl: './version-change-log-dialog.component.html',
    styleUrls: ['./version-change-log-dialog.component.scss']
})
export class VersionChangeLogDialogComponent {
    changeLog = CHANGE_LOG;

    constructor(private readonly dialogRef: MatLegacyDialogRef<VersionChangeLogDialogComponent>) {
    }

    static open(dialog: MatDialog): MatLegacyDialogRef<VersionChangeLogDialogComponent> {
        return dialog.open(VersionChangeLogDialogComponent, {
            width: '800px',
            height: '1000px'
        });
    }

    close(): void {
        this.dialogRef.close();
    }
}
