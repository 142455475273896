import {APP_INITIALIZER, FactoryProvider, Injectable} from '@angular/core';
import {UserSettingsService} from '@core/services/user-settings.service';
import {AuthenticationService} from '@core/services/global/authentication.service';
import {Subject} from 'rxjs';
import {Location} from '@angular/common';
import {AuthRoutes} from '@app/auth/auth-routing.module';
import {AppTranslateService} from '@core/services/global/app-translate.service';

@Injectable({
    providedIn: 'root'
})
/***
 * A service which is used to setup app dependencies.
 */
export class AppInitializerService {
    private hasError = new Subject<boolean>();
    hasError$ = this.hasError.asObservable();

    constructor(private readonly settingsService: UserSettingsService,
                private readonly appTranslateService: AppTranslateService,
                private readonly authService: AuthenticationService,
                private readonly location: Location) {
    }

    async initializeApp(): Promise<void> {
        try {
            if (!this.location.path().includes(AuthRoutes.SwitchUser)) {
                await this.authService.refreshSession();
            }

            this.settingsService.initializeUserSettings();
            this.appTranslateService.loadTranslates();
            this.hasError.next(false);
        } catch (e) {
            console.log(`An error occured during app initialization: ${e}`);
            this.hasError.next(true);
            return;
        }
    }
}

export const appInitializerFactory = (settingsService: UserSettingsService, translateService: AppTranslateService, authService: AuthenticationService, location: Location) =>
    () => new AppInitializerService(settingsService, translateService, authService, location).initializeApp();

export const AppInitializerProvider: FactoryProvider = {
    provide: APP_INITIALIZER,
    useFactory: appInitializerFactory,
    deps: [UserSettingsService, AppTranslateService, AuthenticationService, Location],
    multi: true
};
