<div fxLayout="column" fxFlex fusePerfectScrollbar>
  <mat-list class="date">
    <div class="secondary-text mat-display-1 mb-0 p-16">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <span> {{ date | date: 'd. MMMM' }}</span>
        <a class="subtitle" (click)="closeSelf()" routerLink="notifications">{{ 'TICKET.NOTIFICATIONS_VIEW_ALL' | translate }}</a>
      </div>
    </div>
  </mat-list>

  <mat-divider></mat-divider>

  <omt-notifications-unseen-container></omt-notifications-unseen-container>
</div>
