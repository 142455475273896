<div id="main">
  <!-- TOOLBAR: Above -->
  @if (fuseConfig.layout.toolbar.position === 'above') {
    <ng-container *ngTemplateOutlet="toolbar"></ng-container>
  }
  <!-- / TOOLBAR: Above -->

  <div id="container-1" class="container">
    <!-- NAVBAR: Left -->
    @if (fuseConfig.layout.navbar.position === 'left') {
      <ng-container *ngTemplateOutlet="leftNavbar"></ng-container>
    }
    <!-- / NAVBAR: Left -->

    <div id="container-2" class="container">
      <!-- TOOLBAR: Below fixed -->
      @if (fuseConfig.layout.toolbar.position === 'below-fixed') {
        <ng-container *ngTemplateOutlet="toolbar"></ng-container>
      }
      <!-- / TOOLBAR: Below fixed -->

      <!--     Removed perfect-scroll, as it was preventing containers inside from being scrolled -->
      <!--      fusePerfectScrollbar [fusePerfectScrollbarOptions]="{ suppressScrollX: true, updateOnRouteChange: true }" -->
      <div id="container-3" class="container">
        <!-- TOOLBAR: Below static -->
        @if (fuseConfig.layout.toolbar.position === 'below-static') {
          <ng-container *ngTemplateOutlet="toolbar"></ng-container>
        }
        <!-- / TOOLBAR: Below static -->

        <omt-header></omt-header>

        <!-- CONTENT -->
        <omt-content></omt-content>
        <!-- / CONTENT -->

        <!-- FOOTER: Below static -->
        @if (fuseConfig.layout.footer.position === 'below-static') {
          <ng-container *ngTemplateOutlet="footer"></ng-container>
        }
        <!-- / FOOTER: Below static -->
      </div>

      <!-- FOOTER: Below fixed -->
      @if (fuseConfig.layout.footer.position === 'below-fixed') {
        <ng-container *ngTemplateOutlet="footer"></ng-container>
      }
      <!-- / FOOTER: Below fixed -->
    </div>

    <!-- NAVBAR: Right -->
    @if (fuseConfig.layout.navbar.position === 'right') {
      <ng-container *ngTemplateOutlet="rightNavbar"></ng-container>
    }
    <!-- / NAVBAR: Right -->
  </div>

  <!-- FOOTER: Above -->
  @if (fuseConfig.layout.footer.position === 'above') {
    <ng-container *ngTemplateOutlet="footer"></ng-container>
  }
  <!-- FOOTER: Above -->
</div>

<!-- QUICK PANEL -->
<fuse-sidebar name="quickPanel" position="right" class="quick-panel">
  <quick-panel></quick-panel>
</fuse-sidebar>

<fuse-sidebar name="help" position="right" class="quick-panel">
  <omt-help-panel/>
</fuse-sidebar>
<!-- / QUICK PANEL -->

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- @ PARTIALS                                                                                            -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!--TOOLBAR-->
<ng-template #toolbar>
  @if (!fuseConfig.layout.toolbar.hidden) {
    <toolbar></toolbar>
  }
</ng-template>
<!-- / TOOLBAR -->

<!-- FOOTER -->
<ng-template #footer>
  @if (!fuseConfig.layout.footer.hidden) {
    <footer></footer>
  }
</ng-template>
<!-- / FOOTER -->

<!-- LEFT NAVBAR -->
<ng-template #leftNavbar>
  @if (!fuseConfig.layout.navbar.hidden) {
    <fuse-sidebar name="navbar" class="navbar-fuse-sidebar" [folded]="fuseConfig.layout.navbar.folded" lockedOpen="gt-md"
    >
      <navbar [variant]="fuseConfig.layout.navbar.variant" class="left-navbar"></navbar>
    </fuse-sidebar>
  }
</ng-template>
<!-- / LEFT NAVBAR -->

<!-- RIGHT NAVBAR -->
<ng-template #rightNavbar>
  @if (!fuseConfig.layout.navbar.hidden) {
    <fuse-sidebar name="navbar" position="right" class="navbar-fuse-sidebar" [folded]="fuseConfig.layout.navbar.folded"
                  lockedOpen="gt-md">
      <navbar [variant]="fuseConfig.layout.navbar.variant" class="right-navbar"></navbar>
    </fuse-sidebar>
  }
</ng-template>
<!-- / RIGHT NAVBAR -->
