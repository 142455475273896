import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {AuthenticationService} from '@core/services/global/authentication.service';
import {NavigationService} from '@core/services/global/navigation.service';
import {NotificationHttpService} from '@notifications/notification-http.service';
import {environment} from '@env/environment';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';
import {FuseConfigService} from '@fuse/services/config.service';
import {BehaviorSubject} from 'rxjs';
import {locale as navigationEnglish} from '../../../../../i18n/navigation/en';
import {locale as navigationDeutsch} from '../../../../../i18n/navigation/de';
import {locale as globalEnglish} from '../../../../../i18n/global/en';
import {locale as globalDeutsch} from '../../../../../i18n/global/de';
import {locale as ticketsEnglish} from '../../../../../i18n/tickets/en';
import {locale as ticketsDeutsch} from '../../../../../i18n/tickets/de';
import {locale as timetrackingEnglish} from '../../../../../i18n/timetracking/en';
import {locale as timetrackingDeutsch} from '../../../../../i18n/timetracking/de';
import {locale as boardsEnglish} from '../../../../../i18n/boards/en';
import {locale as boardsDeutsch} from '../../../../../i18n/boards/de';
import {locale as humanresourceDeutsch} from '../../../../../i18n/humanresource/de';
import {locale as humanresourceEnglish} from '../../../../../i18n/humanresource/en';
import {locale as inventoryDeutsch} from '../../../../../i18n/inventory/de';
import {locale as inventoryEnglish} from '../../../../../i18n/inventory/en';
import {FuseTranslationLoaderService} from '@fuse/services/translation-loader.service';
import {FuseNavigation} from '@fuse/types';
import {DialogService} from '@core/services/global/dialog.service';
import {TicketDialogComponent} from '@ticket/ticket-dialog/ticket-dialog.component';
import {UserRoleModel} from '@core/models/user/userrole.model';
import {SubSink} from 'subsink';
import {VersionChangeLogDialogComponent} from '@core/components/version-change-log/version-change-log-dialog.component';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    notificationCount = 0;
    dbName = 'omtask_db';
    production: boolean;
    prodDb: string;
    dialogRef: any;
    environment = environment;
    nodes = new BehaviorSubject<FuseNavigation[]>([]);
    shouldShowSwitcher = false;

    // Private
    private subs = new SubSink();

    private set _userRoles(roles: UserRoleModel) {
        this.shouldShowSwitcher = roles?.ticket && roles?.ticketPlayPause;
    }

    constructor(
        public authService: AuthenticationService,
        private readonly _fuseConfigService: FuseConfigService,
        private readonly _fuseSidebarService: FuseSidebarService,
        private readonly notificationService: NotificationHttpService,
        private readonly _matDialog: MatDialog,
        private readonly navService: NavigationService,
        private readonly _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private readonly dialogService: DialogService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationDeutsch, globalEnglish, globalDeutsch,
            ticketsEnglish, ticketsDeutsch, timetrackingEnglish, timetrackingDeutsch, boardsEnglish, boardsDeutsch, humanresourceDeutsch, humanresourceEnglish,
            inventoryDeutsch, inventoryEnglish);

        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // this.navService.getNavigationNodes().then((nodes) => this.nodes.next(nodes));
        // Subscribe to the config changes
        this.subs.sink = this._fuseConfigService.config.subscribe((settings) => {
            this.horizontalNavbar = settings.layout.navbar.position === 'top';
            this.rightNavbar = settings.layout.navbar.position === 'right';
            this.hiddenNavbar = settings.layout.navbar.hidden === true;
        });

        this.loadData();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }


    loadData(): void {
        this.getUserRoles();

        this.subs.sink = this.notificationService.unseenNotificationCount.subscribe((count) => this.notificationCount = count);

        this.navService.loadIfNodesAreEmpty();
    }

    private getUserRoles(): void {
        this.subs.sink = this.authService.currentUserSubject.subscribe((x) => this._userRoles = x?.userRoles);
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        console.log(value);
    }

    openIssueDialog(): void {
        this.dialogRef = this._matDialog.open(TicketDialogComponent, {
            panelClass: 'scrumboard-card-dialog',
            disableClose: true
        });
    }

    openSettingsDialog(): void {
        this.dialogService.openSettingsDialog(this.authService.currentUser.id);
    }

    openChangeLogDialog(): void {
        VersionChangeLogDialogComponent.open(this._matDialog);
    }
}
