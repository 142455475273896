import {Component, Input} from '@angular/core';
import {CoreModule} from '@core/core.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';

export type VersionInfo = {
    info?: string;
    translationKey?: string;
};

export type Version = {
    releaseDate: Date | null;
    tag: string;
    added: VersionInfo[];
    fixed: VersionInfo[];
    improved: VersionInfo[];
    removed: VersionInfo[];
};

export type Changelog = {
    versions: Version[];
};

@Component({
    selector: 'omt-version-change-log',
    standalone: true,
    imports: [CoreModule, FuseSharedModule, TranslateModule, MatIconModule],
    templateUrl: './version-change-log.component.html',
    styleUrls: ['./version-change-log.component.scss']
})
export class VersionChangeLogComponent {
    @Input() changelog: Changelog;
}
