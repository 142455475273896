import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {FuseSidebarModule} from '@fuse/components';
import {FuseSharedModule} from '@fuse/shared.module';

import {TranslateModule} from '@ngx-translate/core';
import {NavbarModule} from '@core/layouts/components/navbar/navbar.module';
import {ToolbarModule} from '@core/layouts/components/toolbar/toolbar.module';
import {FooterModule} from '@core/layouts/components/footer/footer.module';
import {ContentModule} from '@core/layouts/components/content/content.module';
import {VerticalLayout3Component} from '@core/layouts/vertical/layout-3/layout-3.component';
import {QuickPanelModule} from '@core/layouts/components/quick-panel/quick-panel.module';

@NgModule({
    declarations: [VerticalLayout3Component],
    imports: [RouterModule, FuseSharedModule, FuseSidebarModule, ContentModule, FooterModule, NavbarModule, QuickPanelModule, ToolbarModule,
        TranslateModule],
    exports: [VerticalLayout3Component]
})
export class VerticalLayout3Module {
}
