import {MatLegacyPaginatorIntl as MatPaginatorIntl} from '@angular/material/legacy-paginator';
import {DynamicLocaleId} from '@app/dynamic-locale-id';
import {ValueProvider} from '@angular/core';

const germanRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
        return `0 von ${length}`;
    }


    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} von ${length}`;
};


export const getPaginatorIntl = (): MatPaginatorIntl => {
    const paginatorIntl = new MatPaginatorIntl();

    if (DynamicLocaleId.prototype.toString() !== 'de') {
        return paginatorIntl;
    }

    paginatorIntl.itemsPerPageLabel = 'Einträge pro Seite:';
    paginatorIntl.nextPageLabel = 'Nächste Seite';
    paginatorIntl.previousPageLabel = 'Vorherige Seite';
    paginatorIntl.getRangeLabel = germanRangeLabel;

    return paginatorIntl;
};

export const PaginatorIntlProvider: ValueProvider = {
    provide: MatPaginatorIntl,
    useValue: getPaginatorIntl()
};
