export {};

function toCurrency(this: number): number {
    return this ? +this.toFixed(2) : 0;
}

/**
 * Rounds the number to two fractions.
 */
Number.prototype.toCurrency = toCurrency;
