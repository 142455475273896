import {NgModule} from '@angular/core';
import {MatDividerModule} from '@angular/material/divider';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';

import {FuseSharedModule} from '@fuse/shared.module';
import {CoreModule} from '@core/core.module';
import {QuickPanelComponent} from './quick-panel.component';
import {SharedModule} from '@shared/shared.module';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    declarations: [QuickPanelComponent],
    imports: [MatDividerModule, MatListModule, MatSlideToggleModule, FuseSharedModule, CoreModule, SharedModule, RouterModule, TranslateModule],
    exports: [QuickPanelComponent]
})
export class QuickPanelModule {
}
