import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {delay, filter, take} from 'rxjs/operators';
import {FuseNavigationService} from '@fuse/components/navigation/navigation.service';
import {FusePerfectScrollbarDirective} from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';
import {FuseConfigService} from '@fuse/services/config.service';
import {AuthenticationService} from '@core/services/global/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {SubSink} from 'subsink';

@Component({
    selector: 'navbar-vertical-style-1',
    templateUrl: './style-1.component.html',
    styleUrls: ['./style-1.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavbarVerticalStyle1Component implements OnInit, OnDestroy {
    fuseConfig: any;

    switchedUser = false;
    switchedUserName = '';
    // Private
    private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
    private subs = new SubSink();

    currentUser = this.authService.currentUserSubject;
    slogan = this.translateService.instant('GLOBAL.SLOGAN');

    get isFolded(): boolean {
        return this._fuseSidebarService.getSidebar('navbar').folded && !this._fuseSidebarService.getSidebar('navbar').unfolded;
    }

    constructor(
        private readonly _fuseConfigService: FuseConfigService,
        private readonly _fuseNavigationService: FuseNavigationService,
        private readonly _fuseSidebarService: FuseSidebarService,
        private readonly translateService: TranslateService,
        private readonly _router: Router,
        private readonly authService: AuthenticationService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Directive
    @ViewChild(FusePerfectScrollbarDirective, {static: true})
    set directive(theDirective: FusePerfectScrollbarDirective) {
        if (!theDirective) {
            return;
        }


        this._fusePerfectScrollbar = theDirective;

        this.subs.sink = this._fuseNavigationService.onItemCollapseToggled.pipe(delay(500)).subscribe(() => {
            this._fusePerfectScrollbar.update();
        });

        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                take(1)
            )
            .subscribe(() => {
                setTimeout(() => {
                    this._fusePerfectScrollbar.scrollToElement('navbar .nav-link.active', -120);
                });
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        if (this.authService.previousLoginExists()) {
            this.switchedUser = true;
            this.switchedUserName = this.authService.previousLoginExists();
        }

        this.subs.sink = this._router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                if (this._fuseSidebarService.getSidebar('navbar')) {
                    this._fuseSidebarService.getSidebar('navbar').close();

                    // toggle user is switched

                    if (this.authService.previousLoginExists()) {
                        this.switchedUser = true;
                        this.switchedUserName = this.authService.previousLoginExists();
                    } else {
                        this.switchedUser = false;
                        this.switchedUserName = '';
                    }
                }
            });

        this.subs.sink = this._fuseConfigService.config.subscribe((config) => {
            this.fuseConfig = config;
        });
    }

    switchBack(): void {
        this.authService.restorePreviousToken().then(() => {
            this.switchedUser = false;
            location.reload();
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpened(): void {
        this._fuseSidebarService.getSidebar('navbar').toggleOpen();
    }

    /**
     * Toggle sidebar folded status
     */
    toggleSidebarFolded(): void {
        this._fuseSidebarService.getSidebar('navbar').toggleFold();
    }
}
