@if (variant === 'horizontal-style-1') {
  <navbar-horizontal-style-1></navbar-horizontal-style-1>
}

@if (variant === 'vertical-style-1') {
  <navbar-vertical-style-1></navbar-vertical-style-1>
}

@if (variant === 'vertical-style-2') {
  <navbar-vertical-style-2></navbar-vertical-style-2>
}
