<div class="favorite-list-wrapper">
  @for (group of favorites | groupBy: 'type'; track group) {
    <div>
      <div class="mt-1 mb-1">
        <span class="favorite-group-type">
          @switch (+group.key) {
            @case (favoriteType.Ticket) {
              Tickets
            }
            @case (favoriteType.Board) {
              Boards
            }
            @case (favoriteType.Customer) {
              {{ 'CUSTOMER.CUSTOMERS' | translate }}
            }
            @case (favoriteType.Project) {
              {{ 'MANAGEMENT.PROJECTS' | translate }}
            }
            @case (favoriteType.Article) {
              {{ 'WAREHOUSE.ARTICLES' | translate }}
            }
            @case (favoriteType.WikiArticle) {
              {{ 'KNOWLEDGE_MANAGEMENT.WIKI_ARTICLES' | translate }}
            }
          }
        </span>
      </div>
      @for (favorite of group.values; track favorite) {
        <div class="favorite-entry">
          <omt-favorite-button [type]="getType(favorite.type)" [entityId]="favorite.entityId"></omt-favorite-button>
          @switch (+group.key) {
            @case (favoriteType.Ticket) {
              <ng-container *ngTemplateOutlet="ticket; context: {$implicit: favorite}"></ng-container>
            }
            @case (favoriteType.Board) {
              <ng-container *ngTemplateOutlet="board; context: {$implicit: favorite}"></ng-container>
            }
            @case (favoriteType.Customer) {
              <ng-container *ngTemplateOutlet="customer; context: {$implicit: favorite}"></ng-container>
            }
            @case (favoriteType.Project) {
              <ng-container *ngTemplateOutlet="project; context: {$implicit: favorite}"></ng-container>
            }
            @case (favoriteType.Article) {
            }
            @case (favoriteType.WikiArticle) {
              <ng-container *ngTemplateOutlet="wikiArticle; context: {$implicit: favorite}"></ng-container>
            }
          }
        </div>
        <mat-divider></mat-divider>
      }
    </div>
  }

  @if (!hasSearch && favorites?.length === 0) {
    <omt-missing-entries-info spacing="xs" [infoText]="'GLOBAL.NO_FAVORITES' | translate"
                              [additionalInfo]="'GLOBAL.NO_FAVORITES_INFO' | translate"></omt-missing-entries-info>
  }
  @if (hasSearch && favorites?.length === 0) {
    <span class="p-1">{{ 'GLOBAL.NO_SEARCH_RESULTS' | translate }}</span>
  }
</div>

<ng-template #ticket let-favorite>
  <a [routerLink]="['ticket', favorite.ticket.key]">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">
      <div fxLayout="column">
        @if (favorite.ticket.key) {
          <strong>{{ favorite.ticket.key }}</strong>
        }
        <span class="favorite-entry-name">{{ favorite.ticket.name }}</span>
      </div>
    </div>
  </a>
</ng-template>

<ng-template #customer let-favorite>
  <a [routerLink]="['customer', favorite.customer.id]" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">
    <omt-avatar [avatarUrl]="favorite.customer.image"></omt-avatar>
    <div fxLayout="column">
      @if (favorite.customer.key) {
        <strong>{{ favorite.customer.key }}</strong>
      }
      <span class="favorite-entry-name">{{ favorite.customer.name }}</span>
    </div>
  </a>
</ng-template>

<ng-template #project let-favorite>
  <a [routerLink]="['project-management/detail', favorite.project.id]" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">
    <div fxLayout="column">
      @if (favorite.project.key) {
        <strong>{{ favorite.project.key }}</strong>
      }
      <span class="favorite-entry-name">{{ favorite.project.name }}</span>
    </div>
  </a>
</ng-template>

<ng-template #board let-favorite>
  <a [routerLink]="['boards', favorite.board.id]" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">
    <span class="favorite-entry-name">{{ favorite.board.name }}</span>
  </a>
</ng-template>

<ng-template #wikiArticle let-favorite>
  <a [routerLink]="['knowledge-management/articles', favorite.wikiArticle.id]" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">
    <span class="favorite-entry-name">{{ favorite.wikiArticle.name }}</span>
  </a>
</ng-template>
