import {NgModule} from '@angular/core';
import {MatSidenavModule} from '@angular/material/sidenav';

import {FuseSidebarModule, FuseThemeOptionsModule} from '@fuse/components';
import {FuseSharedModule} from '@fuse/shared.module';
import {NavbarModule} from '@core/layouts/components/navbar/navbar.module';
import {ToolbarModule} from '@core/layouts/components/toolbar/toolbar.module';
import {FooterModule} from '@core/layouts/components/footer/footer.module';
import {ContentModule} from '@core/layouts/components/content/content.module';
import {HorizontalLayout1Component} from '@core/layouts/horizontal/layout-1/layout-1.component';
import {QuickPanelModule} from '@core/layouts/components/quick-panel/quick-panel.module';


@NgModule({
    declarations: [HorizontalLayout1Component],
    imports: [MatSidenavModule, FuseSharedModule, FuseSidebarModule, FuseThemeOptionsModule, ContentModule, FooterModule, NavbarModule, QuickPanelModule, ToolbarModule],
    exports: [HorizontalLayout1Component]
})
export class HorizontalLayout1Module {
}
