import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {FuseSidebarModule} from '@fuse/components';
import {FuseSharedModule} from '@fuse/shared.module';

import {TranslateModule} from '@ngx-translate/core';
import {NavbarModule} from '@core/layouts/components/navbar/navbar.module';
import {VerticalLayout2Component} from '@core/layouts/vertical/layout-2/layout-2.component';
import {ToolbarModule} from '@core/layouts/components/toolbar/toolbar.module';
import {FooterModule} from '@core/layouts/components/footer/footer.module';
import {ContentModule} from '@core/layouts/components/content/content.module';
import {QuickPanelModule} from '@core/layouts/components/quick-panel/quick-panel.module';

@NgModule({
    declarations: [VerticalLayout2Component],
    imports: [RouterModule, FuseSharedModule, FuseSidebarModule, ContentModule, FooterModule, NavbarModule, QuickPanelModule, ToolbarModule,
        TranslateModule],
    exports: [VerticalLayout2Component]
})
export class VerticalLayout2Module {
}
