import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FuseSidebarModule} from '@fuse/components';
import {FuseSharedModule} from '@fuse/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {HeaderModule} from '@core/components/header/header.module';
import {NavbarModule} from '@core/layouts/components/navbar/navbar.module';
import {ToolbarModule} from '@core/layouts/components/toolbar/toolbar.module';
import {VerticalLayout1Component} from '@core/layouts/vertical/layout-1/layout-1.component';
import {FooterModule} from '@core/layouts/components/footer/footer.module';
import {ContentModule} from '@core/layouts/components/content/content.module';
import {QuickPanelModule} from '@core/layouts/components/quick-panel/quick-panel.module';
import {HelpPanelComponent} from '@core/layouts/components/help-panel/help-panel.component';

@NgModule({
    declarations: [VerticalLayout1Component],
    imports: [RouterModule, FuseSharedModule, FuseSidebarModule, ContentModule, FooterModule, NavbarModule, QuickPanelModule, ToolbarModule, TranslateModule, HeaderModule, HelpPanelComponent],
    exports: [VerticalLayout1Component]
})
export class VerticalLayout1Module {
}
