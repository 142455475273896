<div class="help-panel-container p-1" fxLayout="column" fxLayoutAlign="space-between" fxFlex>
  <omt-version-change-log [changelog]="changeLog" class="of-scroll"/>

  <footer class="help-panel-footer mt-1">
    <div fxLayout="row" fxLayoutAlign="space-between">
      <div fxLayout="column">
        <a href="https://wit-con.de" target="_blank">WIT-CON GmbH</a>
        <span>Tannenstr. 11</span>
        <span>56626 Andernach</span>
      </div>
      <div fxLayout="column">
        <span>{{ 'SETTINGS.CONTACT' | translate }}:</span>
        <a href="mailto:hotline@wit-con.de">hotline&commat;wit-con.de</a>
        <span>+49 (0) 2632 31644 00</span>
      </div>
    </div>
  </footer>
</div>
