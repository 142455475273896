import {ApplicationRef, enableProdMode, NgModuleRef} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from 'app/app.module';
import {environment} from '@env/environment';
import 'app/@core/extensions/string';
import 'app/@core/extensions/number';
import 'app/@core/extensions/storage';
import 'app/@core/extensions/date';
import {enableDebugTools} from '@angular/platform-browser';

if (environment.production) {
    enableProdMode();
}


const bootstrap = (): Promise<NgModuleRef<AppModule>> => platformBrowserDynamic().bootstrapModule(AppModule);

bootstrap()
    .then((module) => environment.production ? null : enableDebugTools(module.injector.get(ApplicationRef).components[0]))
    .catch((err) => console.log(err));

