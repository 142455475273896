import {NgModule} from '@angular/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyProgressBarModule as MatProgressBarModule} from '@angular/material/legacy-progress-bar';
import {RouterModule} from '@angular/router';
import {FuseNavigationModule} from '@fuse/components';
import {FuseSharedModule} from '@fuse/shared.module';
import {AvatarModule} from '@libs/ui/src';
import {NavbarVerticalStyle1Component} from '@core/layouts/components/navbar/vertical/style-1/style-1.component';

@NgModule({
    declarations: [NavbarVerticalStyle1Component],
    imports: [MatButtonModule, MatIconModule, MatProgressBarModule, MatDividerModule, FuseSharedModule, FuseNavigationModule, RouterModule, AvatarModule],
    exports: [NavbarVerticalStyle1Component]
})
export class NavbarVerticalStyle1Module {
}
