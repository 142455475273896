<mat-toolbar class="p-0 mat-elevation-z1">
  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
      <div fxHide.xs class="logo ml-12">
        <img fxHide.lt-md class="logo-icon" width="130" height="55" loading="lazy" src="images/logos/logo-no-subline-164x164.png" alt="Logo"/>
        <img fxHide.gt-sm class="logo-icon" width="34" height="34" loading="lazy" src="images/logos/logo-globe-64x64.png" alt="Logo"/>
      </div>

      @if (!hiddenNavbar && !rightNavbar) {
        <button mat-icon-button class="navbar-toggle-button" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
          <mat-icon class="secondary-text">menu</mat-icon>
        </button>
      }

      @if (horizontalNavbar) {
        <div fxLayout="row" fxLayoutAlign="start center">
          <div class="logo ml-16">
            <img class="logo-icon" src="images/logos/fuse.svg"/>
          </div>
        </div>
      }
    </div>


    <div fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
      <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

      <div class="p-1" fxLayout="row" fxLayoutAlign="center center">
        <omt-favorite-panel></omt-favorite-panel>
      </div>

      @if (shouldShowSwitcher) {
        <div class="toolbar-separator" fxHide fxShow.gt-xs></div>
        <omt-current-ticket-switcher></omt-current-ticket-switcher>
      }
      <!-- soll wieder implementiert werden, sobald eine Seitenübergreifende Suche existiert           -->
      <!--            <fuse-search-bar (input)="search($event)"></fuse-search-bar>-->
      <!--            <div class="toolbar-separator"></div>-->
      <ng-container *omtHasPermission="'ticket'">
        <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

        <!-- QUICK ACTIONS -->
        <div class="quick-panel-toggle-button" fxLayout="row" fxLayoutAlign="center center">
          <button [matTooltip]="'TICKET.ADD_EXPRESS_TICKET' | translate" mat-icon-button (click)="openIssueDialog()">
            <i class="fas fa-bolt toolbar-icon"></i>
          </button>
        </div>
        <!-- / QUICK ACTIONS-->
      </ng-container>

      <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

      <!-- NOTIFICATIONS -->
      <div class="quick-panel-toggle-button" fxLayout="row" fxLayoutAlign="center center">
        <button mat-icon-button (click)="toggleSidebarOpen('quickPanel')" aria-label="Toggle quick panel">
          <i [matBadge]="notificationCount.toString()" matBadgeColor="warn" matBadgeSize="small" matBadgePosition="below after" class="fas fa-bell toolbar-icon"></i>
        </button>
      </div>


      <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

      <!-- USER PROFILE -->
      <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
        @if (authService.currentUser) {
          <div fxLayout="row" fxLayoutAlign="center center">
            <omt-avatar class="mr-0 mr-sm-16" size="30" [avatarUrl]="authService.currentUser.avatarUrl"></omt-avatar>
            <span class="username mr-12" fxHide fxShow.gt-sm>{{ authService.currentUser.displayName }}</span>
            <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
          </div>
        }
      </button>

      <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
        @if (authService.currentUser) {
          <button mat-menu-item [routerLink]="['profile', 'general', authService.currentUser.id]">
            <mat-icon>account_circle</mat-icon>
            <span>{{ 'GLOBAL.MY_PROFILE' | translate }}</span>
          </button>
        }
        <!-- LANGUAGE SELECTION -->
        <omt-language-selection-menu/>
        <!-- / LANGUAGE SELECTION -->

        <button (click)="openChangeLogDialog()" aria-label="Toggle quick panel" mat-menu-item>
          <mat-icon>manage_history</mat-icon>
          <span>{{ 'GLOBAL.CHANGES' | translate }}</span>
        </button>

        <button (click)="openSettingsDialog()" mat-menu-item>
          <mat-icon>settings</mat-icon>
          <span>{{ 'GLOBAL.SETTINGS' | translate }}</span>
        </button>

        <button mat-menu-item (click)="authService.logout()">
          <mat-icon>exit_to_app</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>
      <!-- / USER PROFILE -->

      <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

      @if (!hiddenNavbar && rightNavbar) {
        <div class="toolbar-separator" fxHide fxShow.gt-xs></div>
      }

      @if (!hiddenNavbar && rightNavbar) {
        <button mat-icon-button class="navbar-toggle-button" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
          <mat-icon class="secondary-text">menu</mat-icon>
        </button>
      }
    </div>
  </div>
</mat-toolbar>
